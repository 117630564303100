import styled from "@emotion/styled";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1260px;
  text-align: center;
  font-size: 24px;
  font-weight: 300;

  h1 {
    font-size: 72px;
    text-transform: uppercase;
    max-width: 80%;
    margin: 80px auto;
    -webkit-text-stroke: 3px #004aad;
    -webkit-text-fill-color: white;
  }

  h2 {
    font-size: 60px;
    text-transform: uppercase;
    max-width: 80%;
    margin: 80px auto 20px;
    -webkit-text-stroke: 3px #004aad;
    -webkit-text-fill-color: white;
  }

  @media (max-width: 780px) {
    font-size: 20px;

    h1 {
      font-size: 60px;
      margin: 60px auto;
    }

    h2 {
      font-size: 48px;
      margin: 50px auto 0;
    }

    img {
      width: 50%;
    }

  @media (max-width: 640px) {
    font-size: 20px;

    h1 {
      font-size: 50px;
      margin: 60px auto;
    }

    h2 {
      font-size: 38px;
      margin: 50px auto 0;
    }

    img {
      width: 50%;
    }
  }

  @media (max-width: 500px) {
    font-size: 20px;

    h1 {
      font-size: 26px;
      margin: 60px auto 30px;
      -webkit-text-stroke: 1px #004aad;
    }

    h2 {
      font-size: 24px;
      margin: 50px auto 0;
      -webkit-text-stroke: 1px #004aad;
    }

    img {
      width: 50%;
    }
  }
`

export const Box = styled.div`
  padding: 30px 50px;
`

export const BoxGray = styled(Box)`
  background-color: #e8e8e8;
  margin: 30px;
`
