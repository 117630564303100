import React from 'react';
import { Layout } from 'components/Layout';
import { Top } from 'components/Top';
import { Bottom } from 'components/Bottom';
import { Helmet } from 'react-helmet';
import logo from 'images/logo.png';
import {
  Container,
  Box,
  BoxGray,
} from './styles'

const About = () => (
  <Layout>
    <Helmet title="LCK - Quem somos" />
    <Top />
    <Container>
      <h1>Produtos de fixação, máxima eficiência e qualidade</h1>
      <img src={logo} alt="Lucky Grobo logotipo" />

      <BoxGray>
        <p>
          A <strong>Lucky Grobo</strong> atua a anos no seguimento de Produtos de Fixação e sempre
          visando atender os clientes com a máxima eficiência e qualidade,
          oferecendo a melhor solução em termos de fixação.
        </p>
        <p>
          Nossa linha de produtos são de origem totalmente importada, seguimos
          um padrão de qualidade diferenciado no mercado, certificado por
          empresas referências no setor de análise de qualidade.
        </p>
      </BoxGray>

      <h2>Comprometimento, dedicação e agilidade</h2>
      <Box>
        <p>
          Temos como objetivo nos diferenciar pelo comprometimento, dedicação e
          agilidade com que atendemos a nossos clientes, pois entendemos que, para
          fortalecer qualquer parceria, é essencial um atendimento de excelência.
        </p>
        <p>
          <strong>Conheça nosso trabalho, para nós é um prazer atendê-los!!!</strong>
        </p>
      </Box>
    </Container>

    <Bottom />
  </Layout>
)

export default About;
